import React, { useEffect, useState, Fragment, useRef } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import { Spin, Select, Modal, Button, Checkbox, Typography, Form, message } from "antd";
import { UserOutlined, TeamOutlined } from "@ant-design/icons";
import { Divider } from "@material-ui/core";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import debounce from "lodash/debounce";
import { useHistory } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";

import { PermitSection } from "../components/ReviewPermit";
import { PermitUserInfo } from "../components/Permit/PermitUserInfo";
import { CustomButton } from "../components/Shared";
import { PermitStatus, UserRole, UnfilledRequiredFieldsToolTip } from "../config";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  // IsPermitCloser,
  IsUserMTGroupSiteSameWithPermitSite,
  userInApprovedBy,
  getIndividualReviewersOnly,
  getMTGroupApproveCount,
  getIndividualReviewerApproveCount,
  getApproveCountNeeded,
  getNumberOfApproves,
} from "../helpers";
import {
  GET_PERMIT,
  UPDATE_PERMIT_STATUS,
  UPDATE_PERMIT,
  GET_GROUP_LIST,
  GET_ALL_MICRON_USERS,
  UPDATE_PERMIT_REVIEWERS,
  UPDATE_ON_APPROVED_PERMIT,
  REMOVE_INDIVIDUAL_REVIEWER,
  REMOVE_MTGROUP_REVIEWER,
  UPDATE_PERMIT_STATUS_APPROVE_LAST_REVIEWER_REMOVED,
  GET_ALL_MTGROUPS,
  GET_TEMPLATE_LIST,
  SAVE_CHILD_PERMIT,
  GET_ALL_DISCIPLINE,
} from "../graphql/modules";
import { successNotify, warnNotify, getNextApprover } from "../util";

const { Title } = Typography;

const micronEmail = "@micron.com";

const parentPermitUploadFiles = [
  "attachDrawing",
  "uploadRA2",
  "uploadSopMos",
  "uploadReEntryForm",
  "workerCompetencyCertification",
  "uploadRA",
  "nonMicronSubPermitUpload",
];

const { confirm } = Modal;

export const ReviewPermit = ({ match, history }) => {
  const { user: authUser } = useSelector((_state) => _state.auth);
  const formRef = useRef(null);
  const [form] = Form.useForm();

  const { data, loading, refetch } = useQuery(GET_PERMIT, {
    variables: {
      id: match.params.id,
    },
  });

  const [getGroups, { data: GroupList, loading: fetchingGroup }] = useLazyQuery(GET_GROUP_LIST, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  const [getUsers, { data: UserList, loading: fetchingUser }] = useLazyQuery(GET_ALL_MICRON_USERS, {
    variables: {
      limit: 1000,
      offset: 0,
      searchText: "@micron.com",
    },
  });

  const { data: MTGroupList } = useQuery(GET_ALL_MTGROUPS, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  const { data: TemplateList } = useQuery(GET_TEMPLATE_LIST);

  const [getHostOwner, { data: HostOwnerList, loading: fetchingHostOwner }] = useLazyQuery(GET_ALL_MICRON_USERS, {
    variables: {
      limit: 1000,
      offset: 0,
      searchText: micronEmail,
    },
  });

  const { data: DisciplineList } = useQuery(GET_ALL_DISCIPLINE);

  const [ApprovePermitWhenLastReviewerIsRemoved] = useMutation(UPDATE_PERMIT_STATUS_APPROVE_LAST_REVIEWER_REMOVED);
  const [UpdatePermitStatus, { loading: updateStatusLoading }] = useMutation(UPDATE_PERMIT_STATUS);
  const [UpdatePermit, { loading: updateLoading }] = useMutation(UPDATE_PERMIT);
  const [UpdatePermitReviewers] = useMutation(UPDATE_PERMIT_REVIEWERS);
  const [UpdateApprovedPermit, { loading: updateApprovedPermitLoading }] = useMutation(UPDATE_ON_APPROVED_PERMIT);
  const [UpdateRemoveIndividualReviewer] = useMutation(REMOVE_INDIVIDUAL_REVIEWER);
  const [UpdateRemoveMTGroupReviewer] = useMutation(REMOVE_MTGROUP_REVIEWER);
  const [SaveChildPermit, { loading: saveChildPermitLoading }] = useMutation(SAVE_CHILD_PERMIT);

  const [visible, setVisible] = React.useState(false);
  const [reason, setRejectReason] = React.useState("");
  const [state, setState] = useState({});
  const [childrenState, setChildrenState] = useState([]);
  const [mtgroup, setMTGroup] = useState([]);
  const [users, setUsers] = useState([]);

  const [isR1Checked, setIsR1Checked] = useState(false);
  const [isMTChecked, setIsMTChecked] = useState(false);

  const [visibleChildPermit, setVisibleChildPermit] = useState(null);
  const [childtemplate, setChildTemplate] = useState(null);
  const [childSubmissionState, setChildSubmissionState] = useState(null);
  const [disabledButton, setDisabledButton] = useState(false);

  const [hostSystemOwner, setHostSystemOwner] = useState(null);
  const [discipline, setDiscipline] = useState(null);

  const [showFormToolTip, setShowFormToolTip] = useState(false);

  useEffect(() => {
    setTimeout(() => console.log("DisciplineList", DisciplineList?.data), 1000);
  }, []);

  useEffect(() => {
    setState(permit?.submission || {});
    setChildrenState(permit?.children || []);
  }, [data]);

  useEffect(() => {
    if (data) {
      getUsers();
      getGroups();
      getHostOwner();
    }
  }, [data]);

  const onSearchHostOwner = React.useMemo(() => {
    const loadOptions = (value) => {
      getHostOwner({
        variables: {
          limit: 1000,
          offset: 0,
          searchText: value,
        },
      });
    };
    return debounce(loadOptions, 800);
  }, []);

  function onChangeR1Checkbox(e) {
    console.log(`checked = ${e.target.checked}`);
    if (e.target.checked) {
      setIsR1Checked(true);
    } else {
      setIsR1Checked(false);
    }
  }

  function onChangeMTCheckbox(e) {
    console.log(`checked = ${e.target.checked}`);

    if (e.target.checked) {
      setIsMTChecked(true);
    } else {
      setIsMTChecked(false);
    }
  }

  const onSearchUser = React.useMemo(() => {
    const loadOptions = (value) => {
      getUsers({
        variables: {
          limit: 1000,
          offset: 0,
          searchText: value,
        },
      });
    };
    return debounce(loadOptions, 800);
  }, []);

  const onSearchGroup = React.useMemo(() => {
    const loadOptions = (value) => {
      getGroups({
        variables: {
          limit: 10,
          offset: 0,
          searchText: value,
        },
      });
    };
    return debounce(loadOptions, 800);
  }, []);

  const onChange = (values) => {
    // console.log(values)
    setState({ ...state, ...values });
  };

  const onPermitChange = (values) => {
    // console.log("onPermitChange: " + JSON.stringify(values, null, 2));
    setState({ ...state, ...values });
  };

  const onUpdateStatus = async (values) => {
    try {
      const { data } = await UpdatePermitStatus({
        variables: {
          id: match.params.id,
          status: values.status,
          reason: values.reason,
        },
      });
      if (data.UpdatePermitStatus.success) {
        successNotify(data.UpdatePermitStatus.message);
        refetch();
      } else {
        warnNotify(data.UpdatePermitStatus.message);
      }
    } catch (error) {}
  };

  const handleFormTooltipClose = () => {
    setShowFormToolTip(false);
  };

  const handleFormTooltipOpen = () => {
    console.log("There's an empty required field.");
    message.warn("Please check for empty Required Fields.");
    setShowFormToolTip(true);
  };

  const onUpdatePermit = async (values) => {

    try {
      const { data } = await UpdatePermit({
        variables: {
          id: match.params.id,
          status: values.status,
          submission: JSON.stringify(state),
          childrenState: JSON.stringify(childrenState),
          // discipline: discipline ? discipline?._id : selectedDiscipline?._id,
          // hostSystemOwner: hostSystemOwner ? hostSystemOwner?._id : selectedHostOwnerValue?._id,
        },
      });
      if (data.UpdatePermit.success) {
        successNotify(data.UpdatePermit.message);
        setDisabledButton(true);
        refetch();
      } else {
        warnNotify(data.UpdatePermit.message);
      }
    } catch (error) {}
  };

  const onUpdateApprovedPermit = async (values) => {
    try {
      const { data } = await UpdateApprovedPermit({
        variables: {
          id: match.params.id,
          status: values.status,
          submission: JSON.stringify(state),
          childrenState: JSON.stringify(childrenState),
        },
      });
      if (data.UpdatesOnApprovedPermit.success) {
        successNotify(data.UpdatesOnApprovedPermit.message);
        refetch();
      } else {
        warnNotify(data.UpdatesOnApprovedPermit.message);
      }
    } catch (error) {}
  };

  const checkForUploadFile = (e) => {
    // e.preventDefault();
    console.log("checkForUploadFile");

    if (
      status?.hasOwnProperty("attachDrawing") &&
      status?.hasOwnProperty("uploadRA2") &&
      status?.hasOwnProperty("uploadSopMos") &&
      status?.hasOwnProperty("uploadReEntryForm") &&
      status?.hasOwnProperty("workerCompetencyCertification") &&
      status?.hasOwnProperty("uploadRA") &&
      status?.hasOwnProperty("nonMicronSubPermitUpload")
    ) {
      handleApprove();
      console.log("HANDLE APPROVE");
    } else {
      showPromiseConfirm();
    }
  };

  const showPromiseConfirm = () => {
    confirm({
      title: "You are approving a permit.",
      icon: <ExclamationCircleOutlined />,
      content: "Some files are not yet uploaded. Do you want to proceed with approving the permit?",

      onOk() {
        return new Promise((resolve, reject) => {
          handleApprove();
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        }).catch(() => console.log("Oops! There was a problem approving the permit!"));
      },

      onCancel() {},
    });
  };

  const handleApprove = () => {
    try {
      let mtGrpCount = 0;

      let _userId = authUser._id;

      let _approvedBy = permit?.approvedBy;
      let _selectedUser = permit?.selectedUser;
      let _selectedMTGroupUsers = permit?.selectedMTGroupUsers;
      let _selectedMtGroup = permit?.selectedMTGroup;

      let cleanSelectedUsers = getIndividualReviewersOnly(_selectedUser, _selectedMTGroupUsers);

      if (Array.isArray(_selectedMtGroup)) {
        mtGrpCount = _selectedMtGroup.length;
      }

      // let cleanSelectedUsers = _.compact(__selected);
      if (_selectedMtGroup.length === 0) {
        warnNotify("Please select MtGroup!");
      } else if (_selectedUser.length === 0) {
        warnNotify("Please select Individual Reviewer!");
      } else {
        if (authUser.role === UserRole.ContractorPM) {
          onUpdatePermit({ status: PermitStatus.PendingApproval });
        } else if (authUser.role === UserRole.MicronSupervisor) {
          onUpdatePermit({ status: PermitStatus.PendingApproval });
        } else {
          /** Insert MTGroup Review/Approve Logic Here */
          let approveCountNeeded = mtGrpCount + cleanSelectedUsers.length;

          console.log("approveCountNeeded: " + approveCountNeeded);
          console.log("number of approves: " + _approvedBy.length);

          /** START Approval Logic */
          // INDIVIDUAL REVIEWER LOGIC
          if (cleanSelectedUsers.some((user) => user._id === _userId)) {
            console.log("Approving by Individual Reviewer");

            let indApproveCount = 0;
            let mtUsrApproveCount = 0;

            /** Count the number of individual approves */
            _approvedBy.forEach((usr) => {
              if (cleanSelectedUsers.some((o) => o._id === usr._id)) {
                indApproveCount++;
              }
            });

            /** Count the number of MTGroup approves */
            for (let i = 0; i < mtGrpCount; i++) {
              let approvCount = 0;
              _selectedMtGroup[i].members.forEach((usrs) => {
                if (_approvedBy.some((o) => o._id === usrs._id)) {
                  approvCount++;
                }
              });
              mtUsrApproveCount += approvCount;
            }

            console.log(
              "individual Approves: " +
                indApproveCount +
                " | mtGroup Approves: " +
                mtUsrApproveCount +
                " | approve needed: " +
                approveCountNeeded
            );

            let totalApproves = indApproveCount + mtUsrApproveCount + 1;
            // let isPermitApproved = totalApproves + 1;

            // console.log("totalApproves: " + totalApproves);

            console.log("approveCountNeeded: " + approveCountNeeded);
            console.log("Total Number of Approves (including this approval)? " + totalApproves);

            if (totalApproves < approveCountNeeded) {
              console.log("Approved by Individual Reviewer, no change in permit status");
              onUpdatePermit({ status: PermitStatus.PendingApproval });
            }

            if (approveCountNeeded === totalApproves || totalApproves > approveCountNeeded) {
              console.log("Approved by Individual Reviewer, permit status changed");
              onUpdatePermit({ status: PermitStatus.Approved });
            }
          }

          // MTGROUP REVIEWER LOGIC
          if (_selectedMTGroupUsers.some((user) => user._id === _userId)) {
            console.log("Approving by MTGroup Member");
            let indApproveCount = 0;
            let mtUsrApproveCount = 0;

            /** Count the number of individual approves */
            _approvedBy.forEach((usr) => {
              if (cleanSelectedUsers.some((o) => o._id === usr._id)) {
                indApproveCount++;
              }
            });

            /** Count the number of MTGroup approves */
            for (let _i = 0; _i < mtGrpCount; _i++) {
              let approvCount = 0;
              _selectedMtGroup[_i].members.forEach((usrs) => {
                if (_approvedBy.some((o) => o._id === usrs._id)) {
                  approvCount++;
                }
              });
              mtUsrApproveCount += approvCount;
            }

            console.log(
              "individual Approves: " +
                indApproveCount +
                " | mtGroup Approves: " +
                mtUsrApproveCount +
                " | approve needed: " +
                approveCountNeeded
            );

            let totalApproves = indApproveCount + mtUsrApproveCount + 1;
            // let isPermitApproved = totalApproves + 1;

            console.log("approveCountNeeded: " + approveCountNeeded);
            console.log("Total Number of Approves (including this approval)? " + totalApproves);

            if (totalApproves < approveCountNeeded) {
              console.log("Approved by MT Group Reviewer, no change in permit status");
              onUpdatePermit({ status: PermitStatus.PendingApproval });
            }

            if (approveCountNeeded === totalApproves || totalApproves > approveCountNeeded) {
              console.log("Approved by MT Group Reviewer, permit status changed");
              onUpdatePermit({ status: PermitStatus.Approved });
            }
          }
          /** END Approval Logic */

        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleClosed = (e) => {
    e.preventDefault();
    onUpdatePermit({ status: PermitStatus.Completed });
  };

  const handleCancelled = (e) => {
    e.preventDefault();
    onUpdatePermit({ status: PermitStatus.Cancelled });
  };

  const handlePendingClosure = (e) => {
    e.preventDefault();
    var proceedToClose = window.confirm(
      `CAUTION, YOU ARE CLOSING THE PERMIT NOW. NO FURTHER RECTIFICATION IS ALLOWED AFTER PERMIT IS CLOSED.Requester: Please make sure all the documents and checklist filled, uploaded and e-sign attached before click "OK" Continue? `
    );

    if (proceedToClose) {
      onUpdatePermit({ status: PermitStatus.PendingClosure });
    }
  };

  const handlePendingCancel = (e) => {
    e.preventDefault();
    var proceedToCancel = window.confirm(
      `CAUTION, YOU ARE CANCELING THE PERMIT NOW. NO FURTHER RECTIFICATION IS ALLOWED AFTER PERMIT IS CANCELLED. Continue?`
    );

    if (proceedToCancel) {
      onUpdatePermit({ status: PermitStatus.PendingCancel });
    }
  };
  // const handleUpdate = (e) => {
  //   e.preventDefault();
  //   onUpdatePermit({ status: PermitStatus.Approved });
  // };

  const handleUpdateApprovedPermit = (e) => {
    e.preventDefault();
    onUpdateApprovedPermit({ status: PermitStatus.Approved });
  };

  const handleResubmit = (e) => {
    e.preventDefault();
    onUpdatePermit({ status: PermitStatus.Pending });
  };

  const handleReject = (e) => {
    e.preventDefault();
    onUpdateStatus({
      status: PermitStatus.Rejected,
      reason: reason,
    });

    setVisible(false);
  };
  
  useEffect(() => {
    // console.log("childrenState.length: " + childrenState.length);
    // console.log("childrenState: " + JSON.stringify(childrenState, null, 2));    
  }, [childrenState]);

  const onChangeHandler = (values, child) => {
    console.log("\n");
    // console.log("child: " + JSON.stringify(child, null, 2));
    // console.log("values: " + JSON.stringify(values, null, 2));
    const selectedChild = childrenState?.find((d) => d._id === child._id);
    if (selectedChild) {
      console.log("selectedChild is true.");
      Object.assign(selectedChild?.submission, values);
    } else {
      if (childrenState?.length === 0) {
        console.log("childrenState?.length === 0");
        setChildrenState([
          {
            form: child?._id,
            submission: values,
          },
        ]);
      } else {
        const newChild = {
          form: child?._id,
          submission: values,
        };
        setChildrenState([...childrenState, newChild]);
      }
    }
  };

  const onUpdateReviewers = async () => {
    console.log("users: " + JSON.stringify(users, null, 2));
    console.log("mtgroup: " + JSON.stringify(mtgroup, null, 2));

    try {
      let newSelectedUsers = [];
      let newMTGroups = [];
      let reviewersOkToSubmit = false;
      let mtGrpsOkToSubmit = false;

      if (isR1Checked === false && isMTChecked === false) {
        warnNotify("Please tick any checkbox and select reviewer or MTGroup first.");
      } else {
        if ((users === null || users?.length === 0) && (mtgroup === null || mtgroup?.length === 0)) {
          warnNotify("You cannot submit empty fields when updating reviewers.");
          reviewersOkToSubmit = false;
          mtGrpsOkToSubmit = false;
        } else {
          if (isR1Checked) {
            if (users) {
              let hasSameReviewer = permit?.selectedUser.map((usr) => {
                let res = users && users.some((usrId) => usrId.toString() === usr._id.toString());
                return res;
              });

              if (hasSameReviewer.includes(true)) {
                warnNotify("You cannot add the same Individual Reviewer or someone from the selected MTGroup.");
                reviewersOkToSubmit = false;
              } else {
                newSelectedUsers = users;
                reviewersOkToSubmit = true;
              }
            } else {
              warnNotify("You cannot submit empty fields when updating reviewers.");
              reviewersOkToSubmit = false;
            }
          } else {
            console.log("No Change in Individual Reviewer");
            reviewersOkToSubmit = true;
          }

          if (isMTChecked) {
            if (mtgroup) {
              if (permit?.selectedMTGroup) {
                let hasSameMTGroup =
                  permit?.selectedMTGroup &&
                  permit?.selectedMTGroup.map((grp) => {
                    let res = mtgroup && mtgroup.some((sGrp) => sGrp.toString() === grp._id.toString());
                    return res;
                  });
                if (hasSameMTGroup.includes(true)) {
                  warnNotify("You cannot add the same MTGroup.");
                  mtGrpsOkToSubmit = false;
                } else {
                  newMTGroups = mtgroup;
                  mtGrpsOkToSubmit = true;
                }
              } else {
                newMTGroups = mtgroup;
                mtGrpsOkToSubmit = true;
              }
            } else {
              warnNotify("You cannot submit empty fields when updating reviewers.");
              mtGrpsOkToSubmit = false;
            }
          } else {
            console.log("No Change in MT Groups");
            mtGrpsOkToSubmit = true;
          }
        }

        // mutation here, add new selected users to selectedUsers in backend
        // and new MTGroup Members to selectedUsers and selectedMTGroupUsers
        if (reviewersOkToSubmit && mtGrpsOkToSubmit) {
          try {
            console.log("\n===================");
            console.log("permit id: " + permit._id);
            console.log("\nnewSelectedUsers: " + JSON.stringify(newSelectedUsers, null, 2));
            console.log("\nnewMTGroups: " + JSON.stringify(newMTGroups, null, 2));
            console.log("\n===================");

            const { data } = await UpdatePermitReviewers({
              variables: {
                id: permit._id,
                newIndividualReviewers: newSelectedUsers,
                newMTGroups: newMTGroups,
              },
            });

            if (data.UpdatePermitReviewers.success) {
              successNotify(data.UpdatePermitReviewers.message);
              refetch();
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            } else {
              warnNotify(data.UpdatePermitReviewers.message);
            }
          } catch (updErr) {
            console.log(updErr);
          }
        } else {
          console.log("Not submitting updates to backend.");
        }
      }

    } catch (err) {
      console.log("Something went wrong updating reviewers");
      console.log(err);
    }
  };

  const onRemoveIndividualReviewer = async (userId) => {
    try {
      const { data } = await UpdateRemoveIndividualReviewer({
        variables: {
          id: permit?._id,
          userId: userId,
        },
      });

      if (data.UpdateRemoveIndividualReviewer.success) {
        successNotify(data.UpdateRemoveIndividualReviewer.message);
        refetch();
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      } else {
        warnNotify(data.UpdateRemoveIndividualReviewer.message);
      }
    } catch (error) {}
  };

  const handleDelete = (userData) => () => {
    var removeIndividualReviewer = window.confirm(
      `You are removing ${userData?.email} in individual reviewer's list. Continue?`
    );

    if (removeIndividualReviewer) {
      console.log("Removed " + userData?._id + " as individual reviewer on permit " + permit?._id);
      onRemoveIndividualReviewer(userData._id);
    }
  };

  const onRemoveMTGroupReviewer = async (mtgroupId) => {
    let mtgroupCount = getMTGroupApproveCount(permit);
    // let neededApproveCount = getApproveCountNeeded(permit);
    // let numberOfApproves = getNumberOfApproves(permit);
    let approvalRemaining = mtRevsCount - 1;
    console.log("========== TOTAL REVIEWERS =============");

    if (approvalRemaining === 0 && mtgroupCount === 0) {
      warnNotify("MtGroup is required!");
    } else if (approvalRemaining <= 1 && mtgroupCount !== 0) {
      const r = window.confirm(
        "If you remove this last reviewer, the permit status will change to Approved. Continue?"
      );
      if (r) {
        try {
          const { data } = await UpdateRemoveMTGroupReviewer({
            variables: {
              id: permit?._id,
              mtGroupId: mtgroupId,
            },
          });

          if (data.UpdateRemoveMTGroupReviewer.success) {
            successNotify(data.UpdateRemoveMTGroupReviewer.message);
            updatePermitStatusReviewerRemoved();
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          } else {
            warnNotify(data.UpdateRemoveMTGroupReviewer.message);
          }
        } catch (err) {}
      }
    } else {
      try {
        const { data } = await UpdateRemoveMTGroupReviewer({
          variables: {
            id: permit?._id,
            mtGroupId: mtgroupId,
          },
        });

        if (data.UpdateRemoveMTGroupReviewer.success) {
          successNotify(data.UpdateRemoveMTGroupReviewer.message);
          refetch();
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        } else {
          warnNotify(data.UpdateRemoveMTGroupReviewer.message);
        }
      } catch (error) {}
    }
  };

  const updatePermitStatusReviewerRemoved = async () => {
    const { data: approvePermitData } = await ApprovePermitWhenLastReviewerIsRemoved({
      variables: {
        id: permit?._id,
        status: PermitStatus.Approved,
      },
    });

    if (approvePermitData.ApprovePermitWhenLastReviewerIsRemoved.success) {
      successNotify(approvePermitData.ApprovePermitWhenLastReviewerIsRemoved.message);
      setTimeout(function () {
        window.location.reload();
      }, 1500);
    } else {
      warnNotify(approvePermitData.ApprovePermitWhenLastReviewerIsRemoved.message);
    }
  };

  const handleDeleteMTGroup = (mtGroupData) => () => {
    var removeMTGroup = window.confirm(`You are removing ${mtGroupData?.name} in reviewer's list. Continue?`);
    if (removeMTGroup) {
      console.log("Removed " + mtGroupData?._id + " as MTGroup reviewer on permit " + permit?._id);
      onRemoveMTGroupReviewer(mtGroupData._id);
    }
  };

  const IsPermitCloser = (mtGroupData, personId) => {
    let isUserPermitCloser = false;

    try {
      console.log("\n======IS USER PERMIT CLOSER? \n");
      // console.log("mtGroups: " + mtGroupData);

      if (mtGroupData !== null || mtGroupData !== undefined || mtGroupData !== []) {
        console.log("mtGroupData is not null / undefined / empty array");
        for (var i = 0; i < mtGroupData?.length; i++) {
          if (
            mtGroupData[i]?.members &&
            mtGroupData[i]?.members?.some((usr) => usr._id.toString() === personId.toString())
          ) {
            console.log("user is member of " + mtGroupData[i].name + " MTGroup");
            console.log(mtGroupData[i].name + " MTGroup can close permit? " + mtGroupData[i].permitCloser);

            if (mtGroupData[i].permitCloser === true) {
              isUserPermitCloser = true;
            }
            break;
          }
        }
      } else {
        console.log("No MTGroups available.");
        isUserPermitCloser = false;
      }
    } catch (err) {
      console.log(err);
      isUserPermitCloser = false;
    }

    return isUserPermitCloser;
  };

  // const IsUserMTGroupSiteSameWithPermitSite = (mtGroupData, permitSite, personId) => {
  //   let permitSiteSameWithMTGroupSite = false;

  //   try {
  //     if (mtGroupData !== null || mtGroupData !== undefined) {
  //       for (var i = 0; i < mtGroupData.length; i++) {
  //         if (
  //           mtGroupData[i]?.members &&
  //           mtGroupData[i]?.members?.some((usr) => usr?._id.toString() === personId.toString())
  //         ) {
  //           console.log("user is member of " + mtGroupData[i]?.name + " MTGroup");
  //           if (mtGroupData[i].site._id.toString() === permitSite.toString()) {
  //             console.log(
  //               "permit site " +
  //                 permitSite.toString() +
  //                 " is equal to MTGroup Site " +
  //                 mtGroupData[i]?.site?._id.toString() +
  //                 "\n Permit can be cancelled or closed by the user."
  //             );

  //             permitSiteSameWithMTGroupSite = true;
  //           }
  //           break;
  //         }
  //       }
  //     } else {
  //       console.log("No MTGroups available.");
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }

  //   return permitSiteSameWithMTGroupSite;
  // };

  const onSaveAddChildPermit = async () => {
    console.log("permit?.children: " + JSON.stringify(permit?.children, null, 2));
    console.log("childtemplate: " + JSON.stringify(childtemplate, null, 2));
    console.log("childSubmissionState: " + JSON.stringify(childSubmissionState, null, 2));

    try {
      const { data } = await SaveChildPermit({
        variables: {
          id: match.params.id,
          childrenState: JSON.stringify(childSubmissionState),
        },
      });

      if (data.SaveChildPermit.success) {
        successNotify(data.SaveChildPermit.message);
        setChildTemplate(null);
        setChildSubmissionState(null);
        temporaryChildFormState = [];
        refetch();
      } else {
        warnNotify(data.SaveChildPermit.message);
      }
    } catch (error) {}
  };

  let permit = {};
  if (data?.GetPermit?.permit) {
    permit = JSON.parse(data?.GetPermit?.permit || {});
  }

  let selectHostOwnerValue = permit?.hostSystemOwner?.email;
  let selectDisciplineValue = permit?.discipline?.code;

  let selectedDiscipline = permit?.discipline;
  let selectedHostOwnerValue = permit?.hostSystemOwner;

  const selectedHostOwnerProps = {
    style: { width: "100%" },
    value: selectHostOwnerValue,
  };

  const selectedDisciplineProps = {
    style: { width: "100%" },
    value: selectDisciplineValue,
  };

  const approvedBy = permit?.approvedBy?.find((user) => user._id === authUser._id);
  // const contractorPM = permit?.approvedBy?.length > 0 && permit?.approvedBy[0];
  const allApproves = permit?.approvedBy?.length ? permit?.approvedBy : null;
  const status = permit?.status;

  let showReviewActions = false;
  let hideApproveButton = false;
  let updateReviewersComponents = true;
  let showCompleteButton = false;
  let showCancelButton = false;

  let showSaveButtonOnApprovedPermit = false;

  let disableApproveButton = true;

  const mtGroupData = MTGroupList?.GetAllMTGroups?.results ? MTGroupList?.GetAllMTGroups?.results : null;

  let isUserPermitCloser = IsPermitCloser(mtGroupData, authUser._id);
  let isSameSite = IsUserMTGroupSiteSameWithPermitSite(authUser._id, permit?.site);

  console.log("isUserPermitCloser: " + JSON.stringify(isUserPermitCloser, null, 2));
  console.log("isSameSite: " + JSON.stringify(isSameSite));

  if (status === PermitStatus.PendingClosure) {
    if (authUser.role === UserRole.MTGroup) {
      if (isUserPermitCloser) {
        if (isSameSite) {
          showCompleteButton = true;
        }
      }
    }
  }

  if (status === PermitStatus.PendingCancel) {
    if (authUser.role === UserRole.MTGroup) {
      if (isUserPermitCloser) {
        if (isSameSite) {
          showCancelButton = true;
        }
      }
    }
  }

  if (status === PermitStatus.Pending || status === PermitStatus.PendingApproval || status === PermitStatus.Rejected) {
    updateReviewersComponents = false;
  }

  if (status === PermitStatus.Pending && authUser.role === UserRole.ContractorPM) {
    console.log("Permit status is " + status + " | user role is " + authUser.role);
    showReviewActions = true;
    disableApproveButton = false;
  }

  if (authUser.role === UserRole.ContractorPM && status === PermitStatus.PendingApproval) {
    showReviewActions = false;
  }

  if (authUser.role === UserRole.MTGroup && status === PermitStatus.PendingApproval) {
    showReviewActions = true;

    let nextApprover = getNextApprover(permit);
    console.log("nextApprover: " + JSON.stringify(nextApprover, null, 2));

    if (nextApprover.isIndividualReviewer) {
      if (nextApprover.reviewerId === authUser._id) {
        disableApproveButton = false;
      } else {
        disableApproveButton = true;
      }
    } else {
      if (nextApprover.reviewerId === null) {
        disableApproveButton = true;
      } else {
        console.log("nextApprover mtgroup: " + JSON.stringify(nextApprover, null, 2));
        let gMembers = nextApprover?.members?.length ? nextApprover?.members : null;
        if (gMembers !== null) {
          for (let i = 0; i < gMembers.length; i++) {
            if (gMembers[i]?._id == authUser?._id) {
              disableApproveButton = false;
              break;
            }
          }
        }
      }
    }
  }

  if (
    authUser.role === UserRole.MTGroup &&
    status === PermitStatus.Approved &&
    authUser._id !== permit?.submittedBy?._id
  ) {
    showSaveButtonOnApprovedPermit = true;
  }

  if (status === PermitStatus.Rejected) showReviewActions = false;
  if (approvedBy) showReviewActions = false;
  // if (contractorPM.role === UserRole.ContractorPM) showReviewActions = false;

  if (authUser._id === permit?.submittedBy?._id && authUser.role === UserRole.MTGroup) showReviewActions = false;

  if (data?.GetPermit?.success === false) {
    return <div className="card">Not found</div>;
  }

  let _selectedMtGroup = permit?.selectedMTGroup?.length ? permit?.selectedMTGroup : null;

  if (_selectedMtGroup === undefined || _selectedMtGroup === null || _selectedMtGroup === []) {
    console.log("No MTGroup Selected");
    let hideButton = true;

    console.log("======== " + status + " ========= " + authUser.role);

    if (status === PermitStatus.Pending && authUser.role === UserRole.ContractorPM) {
      hideButton = false;
    }

    if (status === PermitStatus.PendingApproval && authUser.role === UserRole.MTGroup) {
      hideButton = false;
    }

    hideApproveButton = hideButton;
  } else {
    let showButton = true;

    for (var mCount = 0; mCount < _selectedMtGroup.length; mCount++) {
      if (_selectedMtGroup[mCount].members.some((p) => p._id === authUser._id)) {
        console.log(
          "User is a member of a selected MTGroup: " +
            _selectedMtGroup[mCount].name +
            "? " +
            _selectedMtGroup[mCount].members.some((p) => p._id === authUser._id)
        );

        // eslint-disable-next-line no-loop-func
        const hasMemberApproved = permit.approvedBy.map((pApproved) => {
          return _selectedMtGroup[mCount].members.some((mmbr) => mmbr._id === pApproved._id);
        });

        if (hasMemberApproved.includes(true)) {
          showButton = true;
          console.log(
            "One members on same MTGroup Approved already. " +
              hasMemberApproved.includes(true) +
              "\nHide approve button? " +
              showButton
          );
          break;
        } else {
          showButton = false;
          console.log(
            "No members on same MTGroup Approved the permit yet. " +
              hasMemberApproved.includes(true) +
              "\nHide approve button? " +
              showButton
          );
          break;
        }
      } else {
        console.log("User is not a member of any selected MTGroup");
        showButton = false;
      }
    }

    hideApproveButton = showButton;
  }

  //MODAL FOR COMMENT REJECT

  const showModal = (record) => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  //MODAL FOR ADDING CHILD PERMIT
  const onOkChildPermit = () => {
    setVisibleChildPermit(false);
  };

  const onChangeChildPermit = (_v, obj) => {
    setChildTemplate(obj);
    // console.log("onChangeChildPermit.obj: " + JSON.stringify(obj[0]?.template?.sections, null, 2));
    obj[0]?.template?.sections?.map((section) => {
      section?.form?.map((frm) => {
        if (frm.required) {
          var obj = {
            name: frm.name,
            required: frm.required,
          };
          temporaryChildFormState.push(obj);
        }
      });
    });
  };

  const handleChildPermit = (_childSubmissionState) => {
    setChildSubmissionState(_childSubmissionState);
  };

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    checkForUploadFile();
  };

  const onFinishFailed = (values) => {
    console.log("onFinishFailed values of form: ", values);
    handleFormTooltipOpen();
  };

  let selectDefaultValue = permit?.selectedUser?.map((user) => {
    if (!permit?.selectedMTGroupUsers?.find((o2) => user.email === o2.email)) {
      return user;
    }
  });

  console.log("hideApproveButton for PM? " + hideApproveButton);

  console.log("=====================================================");
  console.log("getMTGroupApproveCount: " + getMTGroupApproveCount(permit));
  console.log("getIndividualReviewerApproveCount: " + getIndividualReviewerApproveCount(permit));
  console.log("getApproveCountNeeded: " + getApproveCountNeeded(permit));
  console.log("getNumberOfApproves: " + getNumberOfApproves(permit));

  let indRevs = getIndividualReviewersOnly(permit?.selectedUser, permit?.selectedMTGroupUsers);
  let indRevsCount = indRevs?.length ? indRevs?.length : 0;
  let mtRevsCount = permit?.selectedMTGroup?.length ? permit?.selectedMTGroup?.length : 0;
  let totalRevs = indRevsCount + mtRevsCount;

  let showChildPermitSection = permit?.children?.length ? true : false;

  let disableChildPermitSaveButton = childtemplate === null || childtemplate.length <= 0 ? true : false;

  /** This array will be used to get all the required children permit fields, for validation */
  let temporaryChildFormState = [];

  console.log("Number of Reviewers: " + totalRevs);

  console.log("=====================================================");

  return (
    <Spin spinning={loading}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="create-permit-details">
              <div className="form-row">
                <div className="form-group col-md-3" />
                <div className="form-group col-md-3">
                  <Title level={5}>Selected Individual Reviewers</Title>
                  {selectDefaultValue?.map((user) => {
                    if (user) {
                      let userApproved = userInApprovedBy(user?._id, allApproves);
                      let chipColor = "grey";

                      if (userApproved) {
                        chipColor = "primary";
                      }

                      return (
                        <Chip
                          icon={<UserOutlined />}
                          label={user?.email}
                          color={chipColor}
                          onDelete={userApproved ? undefined : totalRevs <= 1 ? undefined : handleDelete(user)}
                          style={{ margin: "0.5%" }}
                        />
                      );
                    }
                  })}
                </div>
                <div className="form-group col-md-3">
                  <Title level={5}>Selected MTGroups</Title>
                  {permit?.selectedMTGroup?.map((mtgroup) => {
                    let memberApproved = false;
                    let members = mtgroup?.members?.length ? mtgroup?.members : null;
                    let chipColor = "grey";

                    let groupHasNoMemberShowToolTip = true;

                    // console.log("members: " + JSON.stringify(members, null, 2));

                    if (members !== null) {
                      for (let i = 0; i < members.length; i++) {
                        let approved = userInApprovedBy(members[i]._id, allApproves);

                        if (approved) {
                          memberApproved = true;
                          chipColor = "primary";
                          break;
                        }
                      }
                    } else {
                      chipColor = "secondary";
                      groupHasNoMemberShowToolTip = false;
                    }

                    return (
                      <Tooltip
                        title={
                          <span style={{ fontSize: "14px" }}>
                            This MTGroup has no members. Please remove from permit and ask admin to add members before
                            adding the MTGroup again as permit reviewer.
                          </span>
                        }
                        placement="top-start"
                        enterDelay={200}
                        leaveDelay={500}
                        disableHoverListener={groupHasNoMemberShowToolTip}
                        style={{ backgroundColor: "red", color: "white" }}
                      >
                        <Chip
                          icon={<TeamOutlined />}
                          label={mtgroup?.name}
                          color={chipColor}
                          onDelete={
                            memberApproved ? undefined : totalRevs <= 1 ? undefined : handleDeleteMTGroup(mtgroup)
                          }
                          style={{ margin: "0.5%" }}
                        />
                      </Tooltip>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="create-permit-details">
              <div className="form-row">
                <div className="form-group col-md-3" />
                <div className="form-group col-md-3" style={{ alignSelf: "flex-end" }}>
                  <Checkbox onChange={onChangeR1Checkbox} disabled={updateReviewersComponents}>
                    {" "}
                    Include to update{" "}
                  </Checkbox>
                  <Select
                    // disabled={isR1Checked}
                    style={{ width: "100%" }}
                    onChange={(value) => setUsers(value)}
                    placeholder="Select Reviewer"
                    showSearch={true}
                    onSearch={onSearchUser}
                    multiple
                    filterOption={false}
                    notFoundContent={fetchingUser ? <Spin size="small" /> : null}
                    mode="multiple"
                    disabled={updateReviewersComponents || !isR1Checked}
                    onSelect={(value) => {
                      var hasDuplicateReviewer;
                      if (mtgroup !== null) {
                        // let result_1 = permit?.selectedMTGroup.filter(o1 => mtgroup?.some(o2 => o1._id === o2));
                        let combinedArray = permit?.selectedMTGroup.map((res) => res.members);
                        let merged = [].concat.apply([], combinedArray);
                        hasDuplicateReviewer = merged.filter(
                          (ol) => ol._id.toLowerCase().indexOf(value.toLowerCase()) !== -1
                        );
                      }

                      if (hasDuplicateReviewer !== undefined && hasDuplicateReviewer.length !== 0) {
                        warnNotify("One of the User in MtGroup has same id in Individual Reviewer.");
                        setUsers([]);
                      }
                    }}
                    value={users}
                  >
                    {UserList?.GetAllMicronUsers?.results
                      ?.sort((a, b) => a.email.localeCompare(b.email))
                      .map((user) => (
                        <Select.Option key={user._id} value={user._id}>
                          <strong>{user.fullname}</strong>
                          <br />
                          <span>{user.email}</span>
                        </Select.Option>
                      ))}
                  </Select>
                </div>
                <div className="form-group col-md-3" style={{ alignSelf: "flex-end" }}>
                  <Checkbox onChange={onChangeMTCheckbox} disabled={updateReviewersComponents}>
                    {" "}
                    Include to update{" "}
                  </Checkbox>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(value) => setMTGroup(value)}
                    placeholder="Select MT Group"
                    showSearch={true}
                    onSearch={onSearchGroup}
                    multiple
                    filterOption={false}
                    notFoundContent={fetchingGroup ? <Spin size="small" /> : null}
                    mode="multiple"
                    disabled={updateReviewersComponents || !isMTChecked}
                    onSelect={(value) => {
                      var hasDuplicate;
                      var hasDuplicateReviewer;
                      let result = GroupList?.GetUserGroups?.results?.filter(
                        (ol) => ol._id.toLowerCase().indexOf(value.toLowerCase()) !== -1
                      );
                      let getMembersToCheck = result.map((res) => res.members);
                      console.log(getMembersToCheck);

                      if (mtgroup !== null) {
                        let combinedArray = permit?.selectedMTGroup?.map((res) => res.members);
                        let merged = [].concat.apply([], combinedArray);
                        hasDuplicate = merged.filter((o1) => getMembersToCheck[0].some((o2) => o1._id === o2._id));
                        hasDuplicateReviewer = users.filter((o1) => getMembersToCheck[0].some((o2) => o1 === o2._id));
                      }

                      if (users !== null) {
                        hasDuplicateReviewer = users.filter((o1) => getMembersToCheck[0].some((o2) => o1 === o2._id));
                      }

                      if (hasDuplicate !== undefined && hasDuplicate.length !== 0) {
                        warnNotify("One of the User has same id in other MTgroup.");
                        setMTGroup([]);
                      } else if (hasDuplicateReviewer !== undefined && hasDuplicateReviewer.length !== 0) {
                        warnNotify("One of the User in MtGroup has same id in Individual Reviewer.");
                        setMTGroup([]);
                      }
                    }}
                    value={mtgroup}
                  >
                    {GroupList?.GetUserGroups?.results
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((group) => (
                        <Select.Option key={group._id} value={group._id}>
                          {group.name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
                <div className="form-group col-md-3" style={{ alignSelf: "flex-end" }}>
                  <Button
                    type="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      onUpdateReviewers();
                    }}
                    disabled={updateReviewersComponents}
                  >
                    Update Reviewers
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Divider />
        <br />

        <div className="row">
          <div className="col-12">
            <div className="create-permit-details">
              <div className="form-row">
                {permit && (
                  <div className="form-group col-md-3">
                    Host/System Owner <br />
                    <Select
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        setHostSystemOwner(value);
                        console.log(hostSystemOwner, "host");
                        // setShowIndividualMTGroup(true);
                      }}
                      showSearch={true}
                      onSearch={onSearchHostOwner}
                      allowClear
                      onClear={() => {
                        getHostOwner();
                      }}
                      filterOption={false}
                      notFoundContent={fetchingHostOwner ? <Spin size="small" /> : null}
                      placeholder="Select Host/System Owner"
                      disabled
                    >
                      {HostOwnerList?.GetAllMicronUsers?.results
                        ?.sort((a, b) => a.email.localeCompare(b.email))
                        .filter((res) => res.email.toLowerCase().indexOf(micronEmail) !== -1)
                        .map((user) => (
                          <Select.Option key={user._id} value={user._id}>
                            <strong>{user.fullname}</strong>
                            <br />
                            <span>{user.email}</span>
                          </Select.Option>
                        ))}
                    </Select>
                    <br />
                  </div>
                )}

                {permit && (
                  <div className="form-group col-md-3">
                    Discipline <br />
                    <Select
                      // {...selectedDisciplineProps}
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        setDiscipline(value);
                        // setShowIndividualMTGroup(true);
                        console.log("disc", discipline);
                      }}
                      placeholder="Select Discipline"
                      disabled
                    >
                      {DisciplineList?.GetAllDisciplines?.results
                        ?.sort((a, b) => a.code.localeCompare(b.code))
                        .map((_site) => (
                          <Select.Option key={_site._id} value={_site._id} site={_site}>
                            {_site.code}
                          </Select.Option>
                        ))}
                    </Select>
                  </div>
                )}
                <div className="form-group col-md-3" />
                <div className="form-group col-md-3" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="heading">
        <Link
          onClick={(e) => {
            e.preventDefault();
            history.push("/permits", {
              query: history.location.state.query,
            });
          }}
          className="btn-link"
        >
          <i className="far fa-arrow-left"></i> Back to view all permit
        </Link>
      </div>

      <PermitUserInfo permit={permit} showAction={false} />

      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={handleFormTooltipOpen}
        name="reviewPermitForm"
        scrollToFirstError
      >
        {permit?.form?.sections?.map((section, key) => (
          <PermitSection
            permitStatus={permit.status}
            section={section}
            key={key}
            onChange={onPermitChange}
            state={permit?.submission}
            formState={state}
            isParent={true}
            siteID={permit?.site}
            handleCheckForUploadFile={checkForUploadFile}
            formRef={formRef}
            permitIndivudualReviewers={permit?.selectedUser}
            permitMtGroupReviewers={permit?.selectedMTGroupUsers}
          />
        ))}

        {/* {childtemplate?.length > 0 && <ChildPermit templates={childtemplate} onChange={onChange} state={state} />} */}

        {showChildPermitSection ? (
          <Fragment>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: "2%" }}>
              <br />
              <h2>Child permit(s)</h2>
            </div>
            {childrenState?.map((child) => (
              <React.Fragment>
                <Divider />
                <div>
                  <h3>{child?.form?.title}</h3>
                </div>

                {child?.form?.sections?.map((_section, _key) => (
                  <div>
                    <PermitSection
                      permitStatus={permit.status}
                      section={_section}
                      templateKey={_key}
                      onChange={(values) => onChangeHandler(values, child)}
                      state={child?.submission}
                      isParent={false}
                      permitIndivudualReviewers={permit?.selectedUser}
                      permitMtGroupReviewers={permit?.selectedMTGroupUsers}
                    />
                  </div>
                ))}
                <br />
              </React.Fragment>
            ))}
          </Fragment>
        ) : (
          <Fragment />
        )}

        {childtemplate?.length > 0 && (
          <ChildPermit
            permitStatus={permit.status}
            templates={childtemplate}
            onChange={onChange}
            state={state}
            parentPermit={permit}
            onHandleSubmission={handleChildPermit}
            isParent={false}
            permitIndivudualReviewers={permit?.selectedUser}
            permitMtGroupReviewers={permit?.selectedMTGroupUsers}
          />
        )}

        {permit?.status === PermitStatus.Rejected ? (
          <div className="text-right w-100">
            <Button type="dashed" size="large" right onClick={() => setVisibleChildPermit(true)}>
              Add child permit
            </Button>
            <Button
              type="primary"
              ghost
              size="large"
              right
              style={{ marginLeft: "1%" }}
              disabled={disableChildPermitSaveButton}
              onClick={(e) => {
                e.preventDefault();
                onSaveAddChildPermit();
              }}
            >
              Save
            </Button>
          </div>
        ) : (
          <Fragment />
        )}

        <Modal
          title="Child permit"
          visible={visibleChildPermit}
          onCancel={() => setVisibleChildPermit(false)}
          onOk={onOkChildPermit}
        >
          <div className="form-row">
            <div className="form-group col-md-4">
              <Select
                mode="multiple"
                style={{ width: 400 }}
                onChange={onChangeChildPermit}
                placeholder="Select template"
              >
                {TemplateList?.GetTemplates?.results?.map((tmp) =>
                  tmp?._id === permit?.form?._id ? null : (
                    <Select.Option key={tmp._id} value={tmp._id} template={tmp}>
                      {tmp.title}
                    </Select.Option>
                  )
                )}
              </Select>
            </div>
          </div>
        </Modal>

        {showReviewActions ? (
          <div className="account-details-btn ">
            <CustomButton
              className="btn btn-primary"
              style={{ marginRight: "1%" }}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/permits/${permit?._id}`);
              }}
              disabled={updateStatusLoading || updateLoading}
            >
              View Permit
            </CustomButton>
            <div hidden={hideApproveButton}>
              <Tooltip
                placement="top-end"
                title={UnfilledRequiredFieldsToolTip}
                disableFocusListener={!showFormToolTip}
                disableHoverListener={!showFormToolTip}
                disableTouchListener={!showFormToolTip}
                onClose={handleFormTooltipClose}
                open={showFormToolTip}
              >
                <CustomButton
                  type="submit"
                  loading={updateLoading}
                  className="btn btn-primary"
                  style={{ maxWidth: 300 }}
                  // onClick={formRef.current.onFinish}
                  // onClick={checkForUploadFile}
                  disabled={updateStatusLoading || updateLoading || disabledButton}
                >
                  Approve and Continue
                </CustomButton>
              </Tooltip>
            </div>
            <CustomButton
              onClick={(event) => {
                event.preventDefault();
                showModal();
              }}
              type="submit"
              loading={updateStatusLoading}
              className="btn btn-danger ml-3"
              disabled={updateStatusLoading || updateLoading}
            >
              Reject
            </CustomButton>
          </div>
        ) : (
          "  "
        )}

        {authUser.role === UserRole.MTGroup && status === PermitStatus.Pending ? (
          <div>
            <CustomButton
              className="btn btn-primary"
              style={{ marginRight: "1%" }}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/permits/${permit?._id}`);
              }}
              disabled={updateStatusLoading}
            >
              View Permit
            </CustomButton>
            <CustomButton
              onClick={(event) => {
                event.preventDefault();
                showModal();
              }}
              type="submit"
              loading={updateStatusLoading}
              className="btn btn-danger ml-3"
              disabled={updateStatusLoading}
            >
              Reject
            </CustomButton>
          </div>
        ) : (
          ""
        )}

        {showCompleteButton ? (
          <div className="account-details-btn">
            <CustomButton
              className="btn btn-primary"
              style={{ marginRight: "1%" }}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/permits/${permit?._id}`);
              }}
              disabled={updateStatusLoading}
            >
              View Permit
            </CustomButton>
            <CustomButton
              type="submit"
              loading={updateStatusLoading}
              className="btn btn-danger ml-3"
              style={{ maxWidth: 300 }}
              onClick={handleClosed}
              disabled={updateStatusLoading}
            >
              Close
            </CustomButton>
          </div>
        ) : showCancelButton ? (
          <div className="account-details-btn">
            <CustomButton
              className="btn btn-primary"
              style={{ marginRight: "1%" }}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/permits/${permit?._id}`);
              }}
              disabled={updateStatusLoading}
            >
              View Permit
            </CustomButton>
            <CustomButton
              type="submit"
              loading={updateStatusLoading}
              className="btn btn-danger ml-3"
              style={{ maxWidth: 300 }}
              onClick={handleCancelled}
              disabled={updateStatusLoading}
            >
              Cancel
            </CustomButton>
          </div>
        ) : (
          ""
        )}

        {(authUser.role === UserRole.ContractorRequestor ||
          authUser.role === UserRole.MTGroup ||
          authUser.role === UserRole.User) &&
        authUser._id === permit?.submittedBy?._id &&
        status === PermitStatus.Approved ? (
          <div className="account-details-btn">
            <CustomButton
              className="btn btn-primary"
              style={{ marginRight: "1%" }}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/permits/${permit?._id}`);
              }}
            >
              View Permit
            </CustomButton>
            <CustomButton
              type="submit"
              loading={updateApprovedPermitLoading}
              className="btn btn-primary"
              style={{ maxWidth: 300 }}
              onClick={handleUpdateApprovedPermit}
              disabled={updateLoading || updateApprovedPermitLoading || updateStatusLoading}
            >
              Save
            </CustomButton>
            <CustomButton
              type="submit"
              loading={updateLoading}
              className="btn btn-primary ml-3"
              style={{ maxWidth: 300 }}
              onClick={handlePendingClosure}
              ddisabled={updateLoading || updateApprovedPermitLoading || updateStatusLoading}
            >
              Pending Closure
            </CustomButton>

            <CustomButton
              type="submit"
              loading={updateStatusLoading}
              className="btn btn-danger ml-3"
              onClick={handlePendingCancel}
              style={{ maxWidth: 300 }}
              disabled={updateLoading || updateApprovedPermitLoading || updateStatusLoading}
            >
              Pending Cancel
            </CustomButton>
          </div>
        ) : (
          ""
        )}

        {(authUser.role === UserRole.ContractorRequestor ||
          authUser.role === UserRole.MTGroup ||
          authUser.role === UserRole.User) &&
        status === PermitStatus.Rejected ? (
          <div>
            <CustomButton
              className="btn btn-primary"
              style={{ marginRight: "1%" }}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/permits/${permit?._id}`);
              }}
              disabled={updateLoading || updateStatusLoading}
            >
              View Permit
            </CustomButton>
            <CustomButton
              type="submit"
              loading={updateLoading}
              className="btn btn-primary"
              style={{ maxWidth: 300 }}
              onClick={handleResubmit}
              disabled={updateLoading || updateStatusLoading}
            >
              Resubmit
            </CustomButton>
            <CustomButton
              type="submit"
              loading={updateStatusLoading}
              className="btn btn-danger ml-3"
              onClick={handleCancelled}
              style={{ maxWidth: 300 }}
              disabled={updateLoading || updateStatusLoading}
            >
              Cancelled
            </CustomButton>
          </div>
        ) : (
          ""
        )}

        {showSaveButtonOnApprovedPermit ? (
          <div className="account-details-btn">
            <CustomButton
              className="btn btn-primary"
              style={{ marginRight: "1%" }}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/permits/${permit?._id}`);
              }}
              disabled={updateApprovedPermitLoading}
            >
              View Permit
            </CustomButton>
            <CustomButton
              type="submit"
              loading={updateApprovedPermitLoading}
              className="btn btn-primary"
              style={{ maxWidth: 300 }}
              onClick={handleUpdateApprovedPermit}
              disabled={updateApprovedPermitLoading}
            >
              Save Permit
            </CustomButton>
          </div>
        ) : (
          ""
        )}
      </Form>

      <Modal title={"Reject Permit"} visible={visible} onOk={handleReject} onCancel={handleCancel}>
        <textarea
          rows={5}
          className="form-control"
          placeholder="Reason"
          name="rejectpermit"
          onChange={(e) => onChange(setRejectReason(e.target.value))}
        ></textarea>
      </Modal>
    </Spin>
  );
};

const ChildPermit = ({ permitStatus, templates, onChange, state, parentPermit, onHandleSubmission, permitIndivudualReviewers, permitMtGroupReviewers }) => {
  const onChangeHandler = (values, children) => {

    const child = state?.children?.find((d) => d.form === children?._id);
    if (child) {
      Object.assign(child.submission, values);
    } else {
      if (state?.children?.length > 0) {
        state.children.push({
          form: children?._id,
          submission: values,
        });
      } else {
        state.children = [
          {
            form: children?._id,
            submission: values,
          },
        ];
      }
    }

    onHandleSubmission(state.children);
  };

  return (
    <Fragment>
      <div style={{ display: "flex", justifyContent: "center", marginBottom: "2%" }}>
        <br />
        <h2>New Child permit(s)</h2>
      </div>

      {templates?.map(({ template: children }, key) => (
        <React.Fragment>
          <Divider />
          <div>
            <h3>{children?.title}</h3>
          </div>
          {children?.sections?.map((section, _key) => (
            <PermitSection
              permitStatus={permitStatus}
              templateKey={key}
              section={section}
              onChange={(values) => onChangeHandler(values, children)}
              state={state}
              childrenId={children?._id}
              isParent={false}
              permitIndivudualReviewers={permitIndivudualReviewers}
              permitMtGroupReviewers={permitMtGroupReviewers}
            />
          ))}
          <br />
        </React.Fragment>
      ))}
    </Fragment>
  );
};
